import { height, options, embed } from "./twitchEmbeddedPlayer"

var currentChannel:string = null;
export function setPlayerTo(channel: any) {
    if (channel != currentChannel) {
        currentChannel = channel;
        var player = embed.getPlayer();
        player.setChannel(channel);
    }
}

$(function () {
    var workercount = 1;
    worker();
    var scrollerheight = height - options.height - 170 - 50;//170 header, 50 pad, player height
    if (scrollerheight > 200) { //min height is 200 for that box
        $("#guidebox").css("max-height", scrollerheight + "px");
    }
    $("h3").first()
        .append('<span style="font-size:15px;"><span class="glyphicon glyphicon-info-sign" font-size="10px !important;" aria-hidden="true" data-toggle="tooltip" data-placement="right" title="The Webcast Guide is the official source of FIRST Robotics Competition live event video and upcoming programming information. Use the box on the lower part of the page to see what events we have scheduled or in  progress."></span></span>');
    //$('[data-toggle="tooltip"]').tooltip();

    function worker() {
        if (workercount < 60) {
            $.ajax({
                url: '/updatelivewebcaststatus',
                success: function (data) {
                    $.each(data, function (i, record) {
                        var b = record.tournamentCode;
                        var c = record.lastMatchPlayed;
                        $("#status_" + b).html(c);
                        var d = record.webcastStatus.isOnline;
                        if (d) {
                            $("#webcaston_" + b).show();
                            $("#webcastoff_" + b).hide();
                        }
                        else {
                            $("#webcastoff_" + b).show();
                            $("#webcaston_" + b).hide();
                        }
                    });
                },
                complete: function () {
                    setTimeout(worker, 300000);
                    workercount++;
                    var dt = new Date($.now());
                    $("#statustooltip").attr("title", "As Of: " + (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes());
                }
            });
        }
        else {
            $("#statusheader").html("<span style=\"color:red\">RefreshRequired</span>");
        }
    };
})
