declare var window: any;

export var width = $(window).width();
export var height = $(window).height();

//Defaults
var defaultChannel = "firstinspires";
export var options = {
    width: "100%",
    height: 450,
    channel: defaultChannel,
};

if (width < 755) {
    options = {
        width: "100%",
        height: 250,
        channel: defaultChannel
    };
}
else if (width < 990) {
    options = {
        width: "725",
        height: 300,
        channel: defaultChannel,
    };
}
else if (width < 1200) {
    options = {
        width: "950",
        height: 400,
        channel: defaultChannel,
    };
}
else {
    //var options = {
    //    width: "100%",
    //    height: 450,
    //    channel: currentChannel,
    //};
}

export var embed = new window.Twitch.Embed("FIRSTplayer", options);
embed.addEventListener(window.Twitch.Embed.VIDEO_READY, () => {

});